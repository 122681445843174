import React from 'react';

const Logo = () => {
    return (
        
        <div className="logo">
           &nbsp; 
        </div>
    );
}

export default Logo;
